<template lang="">
    <div class="card">
        <div class="card-header border-0">
            <div class="card-title w-100 d-flex justify-content-between">
                <h1>Ürünler </h1>
                <router-link 
                replace
                to="add"
                class="btn btn-primary">
                <span class="svg-icon svg-icon-1"><i class="fa fa-plus"></i> </span>Yeni Paket
                
                </router-link>
            </div>
        </div>
      
        <div class="card-body pt-0 pb-5">         
            <div class="d-flex flex-wrap ">    
                <edu-card v-for="(data,i) in datas" :key="i" 
                :id="data.packageId" 
                :title="data.title" 
                :explanation="data.explanation" 
                :totalPrice="data.totalPrice" 
                :color="data.color"
                :image="data.image"
                :icon="data.icon"
                ></edu-card>
            </div>
        </div>
    </div>

</template>
<script lang="js">
import { useStore } from "vuex";
import { computed, onMounted ,onBeforeMount, ref } from "vue";
import store from '@/store';
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import VeriTablo from '@/components/tablo/VeriTablo.vue'
import EmptyModal from '@/components/modals/forms/EmptyModal.vue';
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { inject } from 'vue';

import { ElDropdown } from 'element-plus';
import { ArrowDown } from '@element-plus/icons-vue';

import EduCard from './elements/EduCard.vue';
import  useCruds  from '@/composables/useCrud.js';

export default {
  components: {
    EduCard
  },
    setup() {
        const router = useRouter();
        const tableHeader = [
            {
                name: "Ürün Adı",
                key: "title"
            },
            {
                name: "SKU",
                key: "sku",
            },
             {
                name: "Renk",
                key: "color",
            },
            {
                name: "İşlemler",
                key: "actions",
            },
        ];
        const choosenProduct = ref('');

        const deleteItem = (product) => {
            
            var promise = store.dispatch(Actions.DELETE_PRODUCT,product.productId);
            
            promise.then((value) => {
                if(value.data.success){
                    Swal.fire({
                        text: "Başarılı Bir Şekilde Silindi!",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Tamam!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(function () {
                        
                         for (let i = 0; i < datas.value.length; i++) {
                            if (datas.value[i] === product) {
                                datas.value.splice(i, 1);
                            }
                        }
                       
                    });
                }
                else{
                    Swal.fire({
                        text: "Silinirken bir hata oldu!",
                        icon: "danger",
                        buttonsStyling: false,
                        confirmButtonText: "Tamam!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                        },
                    });
                }
            });
        };

        const chooseProduct = (product) => {
            choosenProduct.value = product;
        };
        // const datas  = computed(() => {
        //     return store.getters.allProducts;
        // });

        const datas = ref([]);

        const links = computed(() => {

            return store.getters.linkObject;
        });
        const metas = computed(() => {
            return store.getters.metaObject;
        });

        const { saveCrud, updateCrud, deleteCrud,loadCrud } = useCruds();



        const emitter  = inject('emitter');
       
        onMounted(() => {
            //store.dispatch(Actions.LOAD_PRODUCTS); 

            loadCrud('/packages').then(function(response){
                if(response.success){

                datas.value = response.data;
                links.value = response.links;
                metas.value = response.meta;
                }
            });
            // emitter.on('PageButtonClicked', (value) => {   
            //     //send the page number as a param to the api
            //     //etc : value => {page : 1}   
            //     console.log("Ürünler Çalıştı");
            //     if(router.currentRoute.value.path == "/product/list"){

            //         store.dispatch(Actions.LOAD_PRODUCTS,value); 
            //     }
            // });
        });
        
       
        return {
            tableHeader,
            datas,
            links,
            metas,
            chooseProduct,
            choosenProduct,
            deleteItem,
        }
    }
}


</script>
<style>

</style>