import { Actions, Mutations } from "@/store/enums/StoreEnums";
import store from '@/store';
import Swal from "sweetalert2/dist/sweetalert2.min.js";


export default function useCruds() {

    const saveCrud = (payload) => new Promise(

        function (resolve, reject) {
            var promise = store.dispatch(Actions.SAVE, payload);

            promise.then(({ data }) => {
                console.log(data);
                if (data.success) {
                    Swal.fire({
                        text: "Başarılı Bir Şekilde Ekledi!",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Tamam!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(function () {
                        resolve(data);
                        // console.log(self.$refs['brandeklemodal__ref']);
                    });
                }
            }).catch(({ response }) => {
                Swal.fire({
                    text: "Yükleme Yaparken bir problemle Karşılaşıldı",
                    icon: "danger",
                    buttonsStyling: false,
                    confirmButtonText: "Tamam!",
                    customClass: {
                        confirmButton: "btn fw-bold btn-light-primary",
                    },
                }).then(function () {
                    reject(false);
                    // console.log(self.$refs['brandeklemodal__ref']);
                });
            });
        }

    );
    const updateCrud = (payload) => new Promise(

        function (resolve, reject) {
            var promise = store.dispatch(Actions.UPDATE, payload);

            promise.then(({ data }) => {
                if (data.success) {
                    Swal.fire({
                        text: "Başarılı Bir Şekilde Güncellendi!",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Tamam!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(function () {

                        resolve(true);

                        // console.log(self.$refs['brandeklemodal__ref']);
                    });
                }
            }).catch(({ response }) => {
                Swal.fire({
                    text: "Güncelleme Yaparken bir problemle Karşılaşıldı",
                    icon: "danger",
                    buttonsStyling: false,
                    confirmButtonText: "Tamam!",
                    customClass: {
                        confirmButton: "btn fw-bold btn-light-primary",
                    },
                }).then(function () {
                    reject(false);
                    // console.log(self.$refs['brandeklemodal__ref']);
                });
            });
        }

    );
    const deleteCrud = (payload) => new Promise(

        function (resolve, reject) {
            var promise = store.dispatch(Actions.DELETE, payload);

            promise.then(({ data }) => {
                if (data.success) {
                    Swal.fire({
                        text: "Başarılı Bir Şekilde Silindi!",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Tamam!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(function () {

                        resolve(true);

                        // console.log(self.$refs['brandeklemodal__ref']);
                    });
                }
            }).catch(({ response }) => {
                Swal.fire({
                    text: "Silinirken bir problemle Karşılaşıldı",
                    icon: "danger",
                    buttonsStyling: false,
                    confirmButtonText: "Tamam!",
                    customClass: {
                        confirmButton: "btn fw-bold btn-light-primary",
                    },
                }).then(function () {
                    reject(false);
                    // console.log(self.$refs['brandeklemodal__ref']);
                });
            });
        }

    );

    const loadCrud = (slug, queries = {}) => new Promise(

        function (resolve, reject) {
            console.log(slug);
            console.log(queries);
            var promise = store.dispatch(Actions.LOAD, { "slug": slug, "queries": queries });
            promise.then(({ data }) => {
                if (data.success) {
                    resolve(data);
                }
            }).catch(({ response }) => {
                reject(response);
            });
        }

    );

    return {
        saveCrud,
        updateCrud,
        deleteCrud,
        loadCrud
    }
}
