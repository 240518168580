<template lang="">
  <div
    class="modal fade"
    :id="modalId"
    ref="addCustomerModalRef"
    tabindex="-1"
    aria-hidden="true" >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header" id="kt_modal_add_customer_header">
          <!--begin::Modal title-->
          <h2 class="fw-bolder">{{ modalTitle }}</h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div
            id="kt_modal_add_customer_close"
            ref="emptymodalclosebtn"
            data-bs-dismiss="modal"
            class="btn btn-icon btn-sm btn-active-icon-primary">
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->
        <!--begin::Form-->

        <!--begin::Modal body-->
        <div class="modal-body py-10 px-lg-17">
          <!--begin::Scroll-->
          <slot name="ModalBody"></slot>
          <!--end::Scroll-->
        </div>
        <!--end::Modal body-->

        <!--begin::Modal footer-->
        <div class="modal-footer flex-center">
          <slot name="ModalFooter"><h1>Default Text</h1></slot>
        </div>
        <!--end::Modal footer-->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["modalId", "modalTitle","isShow"],
  methods: {
    close: function(){
        this.$refs['emptymodalclosebtn'].click();
    }
  },
 watch: {
        isShow: {
          handler(val){
            if(val){
              this.close;
            }
          },
          deep: true
        }
    },

};
</script>
<style lang=""></style>
