<template lang="">

  <li @click="getPage($event,link)" class="page__numbers" :class="getActive">
    <span :class="getIconClass">{{ getContent }}</span>
  </li>
</template>
<script>
import store from '@/store';
import { inject } from 'vue'

import { Actions, Mutations } from "@/store/enums/StoreEnums";
export default {

  props: ["link"],
  computed: {
    getActive: function () {
      if (this.link.active) return "active";
      else return "";
    },
    getIconClass: function () {
      if (this.link.label == "&laquo; Previous") {
        return "material-icons";
      } else if (this.link.label == "Next &raquo;") {
        return "material-icons";
      } else {
        return "";
      }
    },
    getContent: function () {
      if (this.link.label == "&laquo; Previous") {
        return "chevron_left";
      } else if (this.link.label == "Next &raquo;") {
        return "chevron_right";
      } else {
        return this.link.label;
      }
    },
  },

  setup() {
      const emitter = inject('emitter');
    
      const getPage = (product,link) => {
        emitter.emit('PageButtonClicked', {'page': link.label});
      };

      return {
        getPage,
      }
  },
};
</script>
<style></style>
