<template>
    <div class="row mb-5 justify-content-between">
      <div class="col-6 d-flex flex-row align-items-center justify-content-start">
        <div class="">  
            <select class="form-select form-select-solid" aria-label="Select example">      
              <option value="1">10</option>
              <option value="2">50</option>
              <option value="3">100</option>
            </select>
        </div>
        <label class="m-2"> {{ metas.total }} kayıttan {{ metas.from }} ile {{ metas.to }} arası gösteriliyor</label>
      </div>
      <div class="col-3 text-end d-flex align-items-center justify-content-end align-content-center" style="position:relative;">
        <div class="">
          <input type="text" class="form-control form-control-solid" placeholder="Ara..."/>
        </div>
      </div>
    </div>
    <table class="table" style="border-collapse:revert;">
        <thead>
            <tr>
                <th @click="orderRequest($event,header.name)" v-for="(header,index) in headers" :key="'header'+index" scope="col">{{ header.name }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(data,index) in datas" :key="'data'+index">
                <template v-for="(cell, i) in headers" :key="i">
                  <td>
                    <slot :name="`cell-${cell.key}`" :row="data">
                      {{ data[prop] }}
                    </slot>
                  </td>
                </template>
            </tr>
            <tr v-if="datas.length == 0">
              <td :colspan="headers.length">Hiç Kayıt Bulunamadı </td>
            </tr>
        </tbody>
    </table>
    <div id="app" class="pagination-wrapper">     
        <ul class="page" style="background:#f5f8fa;">     
            <page-button  v-for="(link,i) in metas.links" :key="i" :link="link"></page-button>
        </ul>
    </div>
</template>
<script>
  import PageButton from './PageButton.vue';
  import store from '@/store';
  import { Actions, Mutations } from "@/store/enums/StoreEnums";
  export default {
      props:['headers','datas','metas','links'],
      components:{
        PageButton
      },
      computed: {
        totalItem: function(){
          return this.datas.length;
        },
      },
      methods: {
        orderRequest: function(event,headerItem){ 
          store.dispatch(Actions.LOAD_ORDERED_BRANDS);
        },
      },
      
  }
</script>
<style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500;600;700&display=swap');
    :root {
        --primary: #23adad;
        --greyDark: #2d4848;
    }

    th{
        background-color: #f4f7fa !important;
        color: #1e1e2d !important;
        font-family: 'Poppins', Helvetica, "sans-serif";
        font-size:18px !important;
        font-weight:600;
        text-align:center;
        padding:3px !important;
        cursor:pointer;
    }
    td{
      padding:0;
      text-align:center;
      vertical-align:middle;
      padding:30px 0 !important;
    }
          
    html {
      box-sizing: border-box;
      font-size: 62.5%; // 1rem = 10px    100% = 16px
      letter-spacing: 0.6px;
      line-height: 1.4;
      -webkit-user-select: none;

    }

    .pagination-wrapper {
      min-height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: inherit;
      color: #1e1e2d;
    }

    .pagination-wrapper ul {
        list-style-type: none;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.6rem;
        color:#fff;
        box-shadow: 0 0.8rem 2rem rgba(#5a6181, 0.05);
    }

    .items-list {
      max-width: 90vw;
      margin: 2rem;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 3rem;
      justify-content: center;
      align-content: center;

      @media only screen and (max-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .item {
      width: 10rem;
      height: 10rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
      cursor: pointer;

      span {
        background: #ffffff;
        box-shadow: 0 0.8rem 2rem rgba(#5a6181, 0.05);
        border-radius: 0.6rem;
        padding: 2rem;
        font-size: 3rem;
        transition: all 0.3s ease;
      }

      &:hover {
        span {
          transform: scale(1.2);
          color: #009EF7;
        }
      }

      p {
        font-size: 1.2rem;
        margin-top: 1rem;
        color: #fff;
      }
    }

    .page {


      &__numbers,
      &__btn,
      &__dots {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.4rem;
        font-size: 1.4rem;
        cursor: pointer;
        color:#1e1e2d;
      }

      &__dots {
        width: 2.6rem;
        height: 2.6rem;
        cursor: initial;
      }

      &__numbers {
        width: 2.6rem;
        height: 2.6rem;
        border-radius: 0.4rem;
        &:hover {
          color: var(--primary);
        }

        &.active {
          color: #ffffff;
          background:  #009EF7;
          font-weight: 600;
          border: 1px solid #1e1e2d;
        }
      }

      &__btn {
        pointer-events: none;

        &.active {
          color: var(--greyDark);
          pointer-events: initial;

          &:hover {
            color: var(--primary);
          }
        }
      }
    }


</style>