<template>
    <div class="educard mx-3">
        <div class="cardImg">
            <div class="eduName pinktext">TYT</div>
            <div class="cardImgWrapper">
                <div class="cardImgBg pinkbg" :style="{'background-color' : color}"></div>
            </div>
            <img :src="image" alt="">
        </div>
        <div class="cardDetail">
            <div class="eduIcon"><img :src="icon" alt=""></div>
            <h2 class="pinktext">{{ title }}</h2>
            <p class="mt-3">{{ explanation }} </p>
            <div class="eduPrice">
                <div class="discountPrice">{{ totalPrice }} ₺</div>
            </div>
            <ul>
                <li><img src="@/assets/img/ikon4.png" alt=""></li>
                <li><img src="@/assets/img/ikon5.png" alt=""></li>
                <li><img src="@/assets/img/ikon6.png" alt=""></li>
            </ul>
           
        </div>
        <div class="cardLink">
           <router-link :style="{'background-color':color}"  :to="'detail/'+id">
                Paket Detay
           </router-link>
        </div>
    </div>
</template>
<script>
export default {
    props:["title","explanation","totalPrice","color",'id']
}
</script>
<style scoped >
    .educard{
        width:23%;

    }
    .cardImg {
        height: 203px;
        display: flex;
        position: relative;
        justify-content: flex-end;
    }
    .eduName {
        font: normal normal bold 12px/15px Proxima Nova;
        letter-spacing: 0px;
        color: #F3722C;
        background: #FFFFFF;
        box-shadow: 0px 8px 6px #00000029;
        border-radius: 0px 12px 12px 0px;
        position: absolute;
        top: 100px;
        width: 97px;
        height: 20px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0px;
        z-index: 99;
    }
    .pinktext {
        color: #CB1795!important;
    }
    .cardImgWrapper {
        width: 100%;
        height: 150px;
        background: #F2F2F2;
        position: absolute;
        bottom: 0;
        border-radius: 12px 12px 0px 0px;
        border: 10px solid #F2F2F2;
        border-bottom: 0px;
    }
    .cardImg img {
        width: 176px!important;
        height: 204px;
        object-fit: cover;
        z-index: 9;
        float: right;
    }
    .cardDetail {
        width: 100%;
        height:220px;
        padding: 10px 20px;
        background: #F2F2F2;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        border-radius: 0px 0px 12px 12px;
    }
    .cardLink {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .cardImgBg {
        width: 100%;
        height: 100%;
        border-radius: 12px;
    }
    .bluebg {
        background: #4D94E5!important;
    }
    .pink {
        background: #CB1795!important
    }
    .cardLink a {
        background: #F3722C;
        border-radius: 0px 0px 12px 12px;
        width: 100%;
        font: normal normal bold 14px/17px Proxima Nova;
        letter-spacing: 0px;
        color: #FFFFFF;
        text-decoration: none;
        text-align: center;
        height: 50px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 9px;
        margin-top: -15px;
    }
    .regularPrice {
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: center;
        font: normal normal normal 11px/13px Proxima Nova;
        letter-spacing: 0px;
        color: #3B426E;
        margin-bottom: 3px;
        text-decoration: line-through;
    }
    .discountPrice {
        text-align: center;
        color: #FFFFFF;
        background: #45A248;
        border-radius: 11px;
        min-width: 66px;
        height: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .cardDetail ul li img {
width: 22px!important;
height: 22px;
min-width: 22px;
min-height: 22px;
}
.cardDetail ul {
display: flex;
margin-bottom: 10px;
width:100%;
list-style:none;
padding:0 !important;
}
.eduSliderWrapper ul {
width: 100%;
float: left;
list-style:none !important;
}
.cardDetail ul li {
margin: 0 2px;
}
.cardDetail p {
font: normal normal 600 12px/15px Proxima Nova;
letter-spacing: 0px;
color: #3B426E;
text-align: center;
margin-bottom: 26px;
height: 30px;
overflow: hidden;
text-overflow: ellipsis;
white-space: unset;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}
.eduIcon {
position: absolute;
left: -50px;
top: -60px;
}
.eduPrice {
position: absolute;
right: 10px;
bottom: 50px;
}
.btn.btn-link {
    transition: all .2s ease-in-out;
    font: normal normal bold 12px/15px Proxima Nova;
    letter-spacing: 0px;
    color: #45A248;
    border: 1px solid #45A248;
    border-radius: 12px;
    margin: 0 auto;
    padding: 0 10px;
    text-decoration: none;
}
.btn.btn-link:hover {
    color: #fff;
    background: #45A248;
}

@media screen and (max-width: 1560px){
    .educard{
        width:30% !important;
    }
}
</style>